<template>
  <v-container id="users-table" fluid tag="section">
    <base-material-card
      icon="mdi-account"
      inline
      color="secondary"
      class="px-5 py-3 mb-5"
    >
      <template v-slot:heading><v-icon large>mdi-account</v-icon></template>
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $tc("user", 2) }}</b>
        </h1></template
      >
      <v-btn
        color="primary"
        rounded
        @click="add_user(null)"
        absolute
        fab
        top
        right
        small
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-tabs show-arrows v-model="tab">
        <v-tab :id="TableEnum.INVESTOR" :value="TableEnum.INVESTOR">{{
          $tc("investor", 2)
        }}</v-tab>
        <v-tab :id="TableEnum.ADMIN" :value="TableEnum.ADMIN">{{
          $tc("admin", 2)
        }}</v-tab>
        <v-tab
          :id="TableEnum.PARTNER"
          :value="TableEnum.PARTNER"
          data-test="Admin:UsersTable:BtnPartner"
          >{{ $tc("partner", 2) }}</v-tab
        >
        <v-tab
          v-show="1 == 0"
          :id="TableEnum.TRANSLATOR"
          :value="TableEnum.TRANSLATOR"
          >{{ $tc("translator", 2) }}</v-tab
        >
        <v-tab
          :id="TableEnum.FINANCIALANALYSTS"
          :value="TableEnum.FINANCIALANALYSTS"
          >{{ $tc("financial_analyst", 2) }}</v-tab
        >
        <v-tab
          :id="TableEnum.CONTENTANALYSTS"
          :value="TableEnum.CONTENTANALYSTS"
          >{{ $tc("content_analyst", 2) }}</v-tab
        >
        <v-tab
          :id="TableEnum.BANKERS"
          :value="TableEnum.BANKERS"
          data-test="Admin:UsersTable:BtnBanker"
          >{{ $tc("banker", 2) }}</v-tab
        >
        <v-tab :id="TableEnum.ALPHAANALYSTS" :value="TableEnum.BANKERS">{{
          $tc("alpha_analyst", 2)
        }}</v-tab>
        <v-tab
          v-if="envManagerUser === 'TRUE'"
          :id="TableEnum.MANAGER_USER"
          :value="TableEnum.MANAGER_USER"
          >{{ $t("managers") }}</v-tab
        >
      </v-tabs>
      <InvestorTable
        v-show="tab == TableEnum.INVESTOR"
        :UserFullName="user_full_name"
      ></InvestorTable>
      <AdminTable v-show="tab == TableEnum.ADMIN"></AdminTable>
      <PartnerTable v-show="tab == TableEnum.PARTNER"></PartnerTable>
      <FinancialAnalystsTable
        v-show="tab == TableEnum.FINANCIALANALYSTS"
        :UserType="TableEnum.FINANCIALANALYSTS"
      ></FinancialAnalystsTable>
      <ContentAnalystsTable
        v-show="tab == TableEnum.CONTENTANALYSTS"
        :UserType="TableEnum.CONTENTANALYSTS"
      ></ContentAnalystsTable>
      <BankersTable v-show="tab == TableEnum.BANKERS"></BankersTable>
      <AlphaAnalystsTable
        v-show="tab == TableEnum.ALPHAANALYSTS"
        :UserType="TableEnum.ALPHAANALYSTS"
      ></AlphaAnalystsTable>
      <ManagerUserTable
        v-show="tab == TableEnum.MANAGER_USER && envManagerUser === 'TRUE'"
      ></ManagerUserTable>
    </base-material-card>
  </v-container>
</template>

<script>
import AdminTable from "./tabs/AdminTable";
import InvestorTable from "./tabs/InvestorTable";
import PartnerTable from "./tabs/PartnerTable";
import FinancialAnalystsTable from "./tabs/AnalystsTable";
import ContentAnalystsTable from "./tabs/AnalystsTable";
import BankersTable from "./tabs/BankersTable";
import AlphaAnalystsTable from "./tabs/AnalystsTable";
import { TableEnum } from "@/shared/enums/Table";
import ManagerUserTable from "./tabs/ManagerUserTable";

export default {
  name: "UsersTable",

  components: {
    AdminTable,
    InvestorTable,
    PartnerTable,
    FinancialAnalystsTable,
    ContentAnalystsTable,
    BankersTable,
    AlphaAnalystsTable,
    ManagerUserTable,
  },

  data: () => ({
    TableEnum: TableEnum,
    tab: null,
    user_full_name: null,
    envManagerUser: process.env.VUE_APP_MANAGER_USER,
  }),
  async created() {
    if (this.$route.query.user_full_name != undefined) {
      this.user_full_name = this.$route.query.user_full_name;
      this.tab = TableEnum.INVESTOR;
    }
    if (this.$route.query.tab != undefined) {
      this.tab = parseInt(this.$route.query.tab);
    }
  },
  computed: {},
  methods: {
    add_user: function () {
      this.$router.push({
        path: `/users/add`,
      });
    },
  },
};
</script>
